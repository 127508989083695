<template>
  <div id="app" :data-theme="color.preference" class="bg-gray-200 text-black dark:bg-primary-grad dark:text-gray-200">
    <DefaultHeader
        v-if="route?.name !=='category-post'"
        :hero="['index','category'].indexOf(route?.name?.toString()!)!==-1"
    />
    <div class="flex-grow">
      <slot/>
    </div>
    <DefaultFooter/>
  </div>
</template>

<script lang="ts" setup>
import DefaultHeader from "~/layouts/default/DefaultHeader.vue";
import DefaultFooter from "~/layouts/default/DefaultFooter.vue";

const color = useColorMode();
const route = useRoute();

useHead({
  titleTemplate: (title) => ((title ? `${title} | ` : '') + `LocalBytes Blog`),
});

useSeoMeta({
  description: "All the Latest news, tips, and tricks from the Local Bytes Team!",
  ogImage: '/_ipx/_/img/banner-social.png',
  twitterCreator: "@LocalBytesShop",
  author: "LocalBytes",
});
</script>

<style lang="scss">
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}
</style>
